<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="开通类型">
        <el-select v-model="type" placeholder="选择类型" clearable>
          <el-option label="购买会员" value="buy"></el-option>
          <el-option label="系统赠送" value="system"></el-option>
          <el-option label="签到" value="sign"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
      <el-form-item v-permission="'addCoachMemberDay'">
        <el-button type="success" @click="addShow = true">增加天数</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #coach="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.map.coach ? row.map.coach.real_name : "" }}</link-to
          >
        </div>
      </template>
      <template #type="{ row }">
        <div>
          {{ typeMap[row.type] }}
        </div>
      </template>
      <template #remark="{ row }">
        <div class="remark">
          {{ row.remark }}
        </div>
      </template>
      <template #handler="{ row }">
        <el-button size="small" type="success" @click="openDetails(row)"
          >详情</el-button
        >
      </template>
    </auto-table>

    <el-dialog title="增加天数" :visible.sync="addShow" width="50%">
      <el-form>
        <el-form-item label="教练" required>
          <coachSelect :coachid.sync="coachid"></coachSelect>
        </el-form-item>
        <el-form-item label="天数" required>
          <el-input type="number" placeholder="增加天数" v-model="form.day">
          </el-input>
        </el-form-item>

        <el-form-item label="说明" required>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="增加说明"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitHandler">增加天数</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="infoShow" width="50%">
      <el-form v-if="curCoach.id">
        <el-form-item label="教练"> {{curCoach.map.coach.real_name}} </el-form-item>
      </el-form>
       <el-form>
        <el-form-item label="开通类型">{{ typeMap[curCoach.type] }}</el-form-item>
      </el-form>
       <el-form>
        <el-form-item label="操作人"> {{curCoach.handler_name}} </el-form-item>
      </el-form>
       <el-form>
        <el-form-item label="说明"> {{curCoach.remark}} </el-form-item>
      </el-form>
        <el-form>
        <el-form-item label="操作时间"> {{curCoach.create_time}} </el-form-item>
      </el-form>
       <el-form>
        <el-form-item label="开通天数"> {{curCoach.day}}天 </el-form-item>
      </el-form>
      <el-form>
        <el-form-item label="会员过期时间"> {{curCoach.expire_time}} </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from "@/components/select/coachSelect.vue";
export default {
  props: ["cooperator_id"],
  components: {
    coachSelect,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "教练", value: "coach", type: "custom" },
        { name: "天数", value: "day" },
        { name: "开通类型", value: "type", type: "custom" },
        { name: "到期时间", value: "expire_time" },
        { name: "说明", value: "remark", type: "custom" },
        { name: "操作时间", value: "create_time" },
        { name: "操作人", value: "handler_name" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      addShow: false,
      form: {},
      coachid: "",
      typeMap: {
        buy: "购买",
        system: "系统赠送",
        sign: "签到",
      },
      curCoach:{},
      infoShow:false
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    openDetails(row){
        this.curCoach = row
        this.infoShow = true
    },
    submitHandler() {
      if (!this.coachid) {
        this.$message("未选择教练");
        return;
      }
      if (!this.form.day || this.form.day <= 0) {
        this.$message("天数错误");
        return;
      }
      this.$axios({
        url: "/user/coachIdentityRecord/add",
        method: "post",
        data: {
          coach_id: this.coachid,
          day: this.form.day,
          remark: this.form.remark,
          handler_id: this.adminInfo.id,
          handler_name: this.adminInfo.name,
          type: "system",
        },
      }).then((res) => {
        this.form = {};
        this.addShow = false;
        this.getList(1);
      });
    },

    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coachIdentityRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.remark {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>